<template>
  <div class="DomainModification" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Modifier votre domaine</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Une erreur s'est produite">
              <div class="text-center">
                <p>Une erreur s'est produite,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="DomainCreatedModal" id="DomainCreatedModal" size="lg" hide-footer title="Domaine créé">
              <div class="text-center">
                <p><strong>Votre domaine a bien été modifié ! </strong></p>

                <b-button class="mt-3" block pull variant="outline-dark" @click="$router.push('/domains')">
                  Retour à la liste des domaines
                </b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#F9BF3B">
            </loading>

            <div class="text-right">
              <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
            </div>
            <b-form v-on:submit.prevent="modifyDomain()">

              <div class="row">
                <div class="col-sm-6 col-12">
                  <b-form-group id="modify-domain-name-group" label="Nom" label-for="modify-domain-name-input" class="mandatory-input">
                    <b-form-input
                      v-model="modifyDomainName" type="text"
                      maxlength="250"
                      placeholder="Nom (Ex : Le Monde)"
                      id="modify-domain-name-input"
                      @input="$v.modifyDomainName.$touch()"
                      :state="$v.modifyDomainName.$dirty ? !$v.modifyDomainName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.modifyDomainName.$error">Votre nom doit faire entre 2 et 250 caractères</div>
                  </b-form-group>
                </div>
                <div class="col-sm-6 col-12">
                  <b-form-group id="modify-domain-domain_name-group" label="Nom de domaine" label-for="modify-domain-domain_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="modifyDomainDomainName" type="text"
                      maxlength="250"
                      placeholder="Nom de domaine (Ex : https://lemonde.fr)"
                      id="modify-domain-domain_name-input"
                      @input="$v.modifyDomainDomainName.$touch()"
                      :state="$v.modifyDomainDomainName.$dirty ? !$v.modifyDomainDomainName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.modifyDomainDomainName.$error">Votre nom doit faire entre 2 et 250 caractères</div>
                  </b-form-group>
                </div>
              </div>

              <b-form-group id="modify-domain-type-group"
                label="Type de presse"
                label-for="modify-domain-type-input"
                class="mandatory-input">
                <b-form-select
                  v-model="modifyDomainType"
                  id="modify-domain-type-input"
                  @input="$v.modifyDomainType.$touch()"
                  :state="$v.modifyDomainType.$dirty ? !$v.modifyDomainType.$error : null"
                  :options='modifyDomainTypeOptions'>
                </b-form-select>
              </b-form-group>


              <b-form-group id="modify-domain-url_login-group" label="URL de la page de login" label-for="modify-domain-url_login-input" class="mandatory-input">
                <b-form-input
                  v-model="modifyDomainUrlLogin" type="text"
                  maxlength="1023"
                  placeholder="Url du login (Ex : https://lemonde.fr/login)"
                  id="modify-domain-url_login-input"
                  @input="$v.modifyDomainUrlLogin.$touch()"
                  :state="$v.modifyDomainUrlLogin.$dirty ? !$v.modifyDomainUrlLogin.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyDomainUrlLogin.$error">Votre url doit faire entre 2 et 1024 caractères</div>
              </b-form-group>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <b-form-group id="modify-domain-login-group" label="Identifiant" label-for="modify-domain-login-input" class="mandatory-input">
                    <b-form-input
                      v-model="modifyDomainLogin" type="text"
                      maxlength="250"
                      placeholder="Identifiant"
                      id="modify-domain-login-input"
                      @input="$v.modifyDomainLogin.$touch()"
                      :state="$v.modifyDomainLogin.$dirty ? !$v.modifyDomainLogin.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.modifyDomainLogin.$error">Votre identifiant doit faire entre 2 et 255 caractères</div>
                  </b-form-group>
                </div>
                <div class="col-sm-6 col-12">
                  <b-form-group id="modify-domain-password-group" label="Mot de passe" label-for="modify-domain-password-input" class="mandatory-input">
                    <b-form-input
                      v-model="modifyDomainPassword" type="text"
                      maxlength="250"
                      placeholder="Mot de passe"
                      id="modify-domain-password-input"
                      @input="$v.modifyDomainPassword.$touch()"
                      :state="$v.modifyDomainPassword.$dirty ? !$v.modifyDomainPassword.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.modifyDomainPassword.$error">Votre mot de passe doit faire entre 2 et 255 caractères</div>
                  </b-form-group>
                </div>
              </div>

              <b-form-group id="modify-domain-xpath_login-group" label="XPath Login" label-for="modify-domain-xpath_login-input" class="mandatory-input">
                <b-form-input
                  v-model="modifyDomainXPathLogin" type="text"
                  maxlength="250"
                  placeholder="XPath du login (Ex : //*[@id='login'])"
                  id="modify-domain-xpath_login-input"
                  @input="$v.modifyDomainXPathLogin.$touch()"
                  :state="$v.modifyDomainXPathLogin.$dirty ? !$v.modifyDomainXPathLogin.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyDomainXPathLogin.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="modify-domain-xpath_password-group" label="XPath Password" label-for="modify-domain-xpath_password-input" class="mandatory-input">
                <b-form-input
                  v-model="modifyDomainXPathPassword" type="text"
                  maxlength="250"
                  placeholder="XPath du password (Ex : //*[@id='password'])"
                  id="modify-domain-xpath_password-input"
                  @input="$v.modifyDomainXPathPassword.$touch()"
                  :state="$v.modifyDomainXPathPassword.$dirty ? !$v.modifyDomainXPathPassword.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyDomainXPathPassword.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="modify-domain-xpath_button-group" label="XPath Bouton" label-for="modify-domain-xpath_button-input" class="mandatory-input">
                <b-form-input
                  v-model="modifyDomainXPathButton" type="text"
                  maxlength="250"
                  placeholder="XPath du password (Ex : //*[@id='button'])"
                  id="modify-domain-xpath_button-input"
                  @input="$v.modifyDomainXPathButton.$touch()"
                  :state="$v.modifyDomainXPathButton.$dirty ? !$v.modifyDomainXPathButton.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyDomainXPathButton.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="modify-domain-xpath_clics-group" label="XPath Clics" label-for="modify-domain-xpath_clics-input">
                <b-form-input
                  v-model="modifyDomainXPathClics" type="text"
                  maxlength="250"
                  placeholder="XPath des clics avant login (séparés par ; si plusieurs à la suite)"
                  id="modify-domain-xpath_clics-input"
                  @input="$v.modifyDomainXPathClics.$touch()"
                  :state="$v.modifyDomainXPathClics.$dirty ? !$v.modifyDomainXPathClics.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyDomainXPathClics.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="create-domain-iframe-group" label="Id de l'iframe de login" label-for="create-domain-iframe-input">
                <b-form-input
                  v-model="modifyDomainIframeConnexionId" type="text"
                  maxlength="255"
                  placeholder="Id de l'iframe autour du login"
                  id="create-domain-iframe-input"
                  @input="$v.modifyDomainIframeConnexionId.$touch()"
                  :state="$v.modifyDomainIframeConnexionId.$dirty ? !$v.modifyDomainIframeConnexionId.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.modifyDomainIframeConnexionId.$error">Votre iframe doit faire entre 2 et 255 caractères</div>
              </b-form-group>

              <b-button
                pill block type="submit" variant="outline-dark" class="mt-2"
                :disabled="isInvalidDomain">
                  Modifier votre domaine
              </b-button>
            </b-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { APIDomain } from '@/api/APIDomain'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiDomain = new APIDomain()

export default {
  name: 'DomainModification',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      isDomainLoading: false,

      domain: {
        id: ''
      },

      modifyDomainName: '',
      modifyDomainDomainName: '',
      modifyDomainType: 'PPR',
      modifyDomainUrlLogin: '',
      modifyDomainLogin: '',
      modifyDomainPassword: '',
      modifyDomainXPathLogin: '',
      modifyDomainXPathPassword: '',
      modifyDomainXPathButton: '',
      modifyDomainIframeConnexionId: '',
      modifyDomainXPathClics: '',
      modifyDomainInProcess: false,

      modifyDomainTypeOptions: [
        { text: 'Presse quotidienne régionale', value: 'PQR' },
        { text: 'Presse hebodmadaire régionale', value:  'PHR' },
        { text: 'Presse professionnelle', value:  'PPR' },

      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidDomain () {

      if (this.$v.$invalid) {
        return true
      }
      return false
    },
    isLoading () {
      if (this.modifyDomainInProcess || this.isDomainLoading) {
        return true
      }
      return false
    },
  },
  validations: {
    modifyDomainName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    modifyDomainDomainName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    modifyDomainType: {
      required
    },
    modifyDomainUrlLogin: {
      minLength: minLength(2),
      maxLength: maxLength(1024),
      required
    },
    modifyDomainLogin: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    modifyDomainPassword: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    modifyDomainXPathLogin: {
      minLength: minLength(2),
      maxLength: maxLength(512),
      required
    },
    modifyDomainXPathPassword: {
      minLength: minLength(2),
      maxLength: maxLength(512),
      required
    },
    modifyDomainXPathButton: {
      minLength: minLength(2),
      maxLength: maxLength(512),
      required
    },
    modifyDomainIframeConnexionId: {
      minLength: minLength(2),
      maxLength: maxLength(255),
    },
    modifyDomainXPathClics: {
      minLength: minLength(2),
      maxLength: maxLength(512),
    },
  },

  created: function() {
    this.getDomain()
  },

  methods: {
    getDomain () {
      this.isDomainLoading = true
      apiDomain.getDomain(this.token, this.$route.params.id)
        .then((result) => {
          this.domain = result.data

          this.modifyDomainName = result.data.name
          this.modifyDomainDomainName= result.data.domain_name
          this.modifyDomainType = result.data.type
          this.modifyDomainUrlLogin = result.data.url_login
          this.modifyDomainLogin = result.data.login
          this.modifyDomainPassword = result.data.password
          this.modifyDomainXPathLogin = result.data.xpath_login
          this.modifyDomainXPathPassword = result.data.xpath_password
          this.modifyDomainXPathButton = result.data.xpath_button
          this.modifyDomainIframeConnexionId = result.data.iframe_connexion_id
          this.modifyDomainXPathClics = result.data.xpath_clics

        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('ProfileEdition getConnectedCollaborateur API Error : ' + String(error))
        })
        .finally(() => {
          this.isDomainLoading = false
        })
    },

    modifyDomain () {
      this.modifyDomainInProcess = true
      apiDomain.modifyDomain(
        this.token, this.domain.id, this.modifyDomainDomainName, this.modifyDomainIframeConnexionId, this.modifyDomainLogin,
        this.modifyDomainName, this.modifyDomainPassword, this.modifyDomainType, this.modifyDomainUrlLogin, this.modifyDomainXPathClics,
        this.modifyDomainXPathLogin, this.modifyDomainXPathPassword, this.modifyDomainXPathButton)
      .then(() => {
        this.$refs.DomainCreatedModal.show()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DomainModification modifyDomain API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyDomainInProcess = false
      })

    },
  }
}
</script>
